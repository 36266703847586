//list in a DataGrid from 'react-data-grid', records  from fisioterapia
import 'react-data-grid/lib/styles.css';
import React, { useState, useEffect } from 'react';
import Datagrid from 'react-data-grid';
import { getFisioAll } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ListaFisio = ({ admin }) => {

    const fisio_list = useSelector(state => state.fisio.fisio);
    const dispatch = useDispatch();    

    useEffect(() => {
        dispatch(getFisioAll());
    }, [dispatch]);

    const columns = [
        { key: 'data_criacao', name: 'Data' },
        { key: 'jogo', name: 'Jogo' },
        { key: 'campo', name: 'Campo' },
        { key: 'escalao', name: 'Escalão' },
        { key: 'regiao', name: 'Região' },
        { key: 'zona', name: 'Zona' },
        { key: 'lateral', name: 'Lateralidade' },
        { key: 'lesao', name: 'Lesão' },
        { key: 'aptidao', name: 'Aptidão' },
        { key: 'contacto', name: 'Contacto' },
        { key: 'observacoes', name: 'Observações' }
    ];

    return (
        <>
            {fisio_list ? 
             <>
             <Datagrid
                columns={columns}
                rows={fisio_list}
                sortColumns={[{ key: 'data_criacao', direction: 'desc' }]}
                rowsPerPageOptions={[5, 10, 20]}
                style={{ width: '100%', height: '100%' }}
            
            />
            <Link to={ `${admin ? '/admin' : '' }/`}><Button variant="secondary" size="lg" block>Voltar</Button></Link>
            </>
            :
          <Row className="mb-3">
             <Col className="d-flex justify-content-center align-items-center">
                        <Spinner animation="border" variant="secondary" />
                    </Col>
                </Row>
            }
        </>
    );
}


export default ListaFisio