//create a form using react-bootstrap components and react-hook-form. Validation is done with yup. The form is submitted to a database using axios and react-redux.
//the form has four areas, separated by Cards:
// 1. Info Jogo. the area is comprised of three Form.Select components. They are cascade components. when ou select a value in the first one, the second one is populated with values from a database. When you select a value in the second one, the third one is populated with values from a database. the first one is escalao. the second one is campo and the third one is jogo.
// 2. Zona Lesão. this area is also comprised of three Form.Select components. They are cascade components. when ou select a value in the first one, the second one is populated with values from a database. When you select a value in the second one, the third one is populated with values from a database. the first one is zona. the second one is regiao and the third one is lateralidade.
// 3. Tipo Lesão. this area has two radio groups with two options each and a Form.Select component lesao. the first one is tipoLesao, that has the options 'Com contacto/Sem contacto' and the second one is apto which has the options 'Apto/Inapto'. the third one is lesao, which is populated with values from a database.
// 4. Observacoes. this has a text area for comments and a button to submit the form.
// Path: src\components\inscricoes\formInscricao.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJogos, getFisioLov, addFisio } from '../../store/actions';
import { Row, Col, Form, FloatingLabel, Card, Button, Spinner } from 'react-bootstrap';
import FormFisioJogos from './formFisioJogos';
import FormFisioZona from './formFisioZona';
import FormFisioLesao from './formFisioLesao';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const schema = yup.object().shape({
    escalao: yup.number().required("O escalão é obrigatório"),
    campo: yup.number().required("O campo é obrigatório"),
    jogo: yup.number().required("O jogo é obrigatório"),
    aptidao: yup.string().required("A aptidão é obrigatória"),
    zona: yup.string().required("A zona é obrigatória"),
    regiao: yup.string().required("A região é obrigatória"),
    lateral: yup.string().required("A lateralidade é obrigatória"),
    lesao: yup.string().required("A lesão é obrigatória"),
    contacto: yup.string().required("O contacto é obrigatório"),
    observacoes: yup.string().max(2000)
});


const FormFisio = ({admin}) => {

    const dispatch = useDispatch();
    const jogos = useSelector(state => state.jogos.jogos);
    const fisio = useSelector(state => state.fisio?.fisiolov);
    const lesao = useSelector(state => state.fisio?.lesaolov);
    const navigateOk = useSelector(state => state.fisio?.navigate);

    const navigate = useNavigate();

    useEffect(() => {
        if (navigateOk == 'OK') {
          navigate( `${admin ? '/admin' : '' }/`)
        }
      }, [navigate, navigateOk])

    const {
        register,
        handleSubmit,
        getValues,
        control,
        setValue,
        formState: { errors, isSubmitSuccessful, isSubmitting }
    } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmitHandler = (data) => {
        return new Promise((resolve) => {
            data.data_criacao = moment().format('YYYY-MM-DD HH:mm:ss')
            resolve(dispatch(addFisio(data)));
        });
    };

    useEffect(() => {
        dispatch(getJogos());
        dispatch(getFisioLov());
    }, [dispatch]);

    return (
        <>
            {jogos ?
                (<>
                    <Form noValidate  onSubmit={handleSubmit(onSubmitHandler)}>
                        <FormFisioJogos errors={errors} register={register} jogos={jogos} ></FormFisioJogos>
                        <FormFisioZona errors={errors} register={register} fisio={fisio} ></FormFisioZona>
                        <FormFisioLesao errors={errors} register={register} lesao={lesao} ></FormFisioLesao>
                        <Row>
                            <Col className="d-flex flex-row justify-content-start">
                                <Button disabled={isSubmitting} className="registerButton" type="submit">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Submeter
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </>
                )
                :
                <Row className="mb-3">
                    <Col className="d-flex justify-content-center align-items-center">
                        <Spinner animation="border" variant="secondary" />
                    </Col>
                </Row>

            }
        </>
    )
}

export default FormFisio;