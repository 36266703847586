import axios from 'axios';
//import {
//    createClient
//} from 'webdav';
//const URL_SERV = "https://pombalcup-24179.nodechef.com";
const URL_SERV = "https://nodejsbo.pombalcup.pt/";
const FILE_SERVER = 'https://pombalcup.pt/owncloud/remote.php/dav/files/pombalcup/';
//const URL_SERV = "http://localhost:3003";

export const getEscaloes = async () => {
    try {
        // http://localhost:3004/posts?_page=1&_limit=6&_order=desc&_sort=id
        const response = await axios.get(`${URL_SERV}/api/escaloes`);

        return {
            escaloes: response.data,
        }
    } catch (error) {
        throw error;
    }
}

export const getJogos = async () => {
    try {
        // http://localhost:3004/posts?_page=1&_limit=6&_order=desc&_sort=id
        const response = await axios.get(`${URL_SERV}/api/jogos`);

        return {
            jogos: response.data,
        }
    } catch (error) {
        throw error;
    }
}

export const getClassificacao = async () => {
    try {
        // http://localhost:3004/posts?_page=1&_limit=6&_order=desc&_sort=id
        const response = await axios.get(`${URL_SERV}/api/classificacao`);

        return {
            classificacao: response.data,
        }
    } catch (error) {
        throw error;
    }
}

export const getJogoById = async (id) => {
    try {
        const response = await axios.get(`${URL_SERV}/api/jogos/${id}`);
        return response.data;
    } catch (error) {
        return '404'
    }
}

export const adicionarGolo = async (id, data) => {
    try {
        await axios({
            method: 'POST',
            url: `${URL_SERV}/api/jogos/${id}/golo`,
            data: data
        });

        return {
            status: 'OK'
        }
    } catch (error) {
        throw error;
    }
}

export const finalizaJogo = async (id) => {
    try {
        await axios({
            method: 'POST',
            url: `${URL_SERV}/api/jogos/${id}/finalizar`,
        });

        return {
            status: 'OK'
        }
    } catch (error) {
        throw error;
    }
}

export const iniciaJogo = async (id) => {
    try {
        await axios({
            method: 'POST',
            url: `${URL_SERV}/api/jogos/${id}/iniciar`,
        });

        return {
            status: 'OK'
        }
    } catch (error) {
        throw error;
    }
}

export const getEquipa = async (id) => {
    try {
        const response = await axios.get(`${URL_SERV}/api/inscricoes/${id}`);
        console.log(response);
        return response.data;

    } catch (error) {
        return '404'
    }
}

export const gravarEquipa = async (id, data, file) => {
    try {

        /*try {
            const client = createClient(FILE_SERVER, {
                username: 'pombalcup',
                password: 'Roma_2019'
            });
            console.log(file);
            if (file && file.result && file.extension)
                await client.putFileContents(`/Logo_Equipa_${id}.${file.extension}`, file.result);    
        } catch (error) {
            console.log('Deu erro a colocar o ficheiro...não é grave! ' + error)
        }*/

        const response = await axios.post(`${URL_SERV}/api/inscricoes/${id}`, data);
        return response.data;

    } catch (error) {
        console.log(error);
        return '404'
    }
}

export const removerMarcador = async (id, obj) => {
    try {
        await axios({
            method: 'POST',
            url: `${URL_SERV}/api/jogos/${id}/remover`,
            data: {idmarcador: obj.marcador_id, equipas_id: obj.equipas_id}
        });

        return {
            status: 'OK'
        }
    } catch (error) {
        throw error;
    }
}

export const getFisioAll = async () => {
    try {
        const response = await axios.get(`${URL_SERV}/api/fisio/all`);
        return response.data;

    } catch (error) {
        return '404'
    }
}

export const getFisioLov = async () => {
    try {
        const response = await axios.get(`${URL_SERV}/api/fisio/lov`);
        return response.data;

    } catch (error) {
        return '404'
    }
}

export const addFisio = async (data) => {
    try {
        const response = await axios.post(`${URL_SERV}/api/fisio/add`, data);
        return response.data;

    } catch (error) {
        return '404'
    }
}

export const getEquipas = async () => {
    try {
        const response = await axios.get(`${URL_SERV}/api/equipas`);
        return response.data;
    } catch (error) {
        return '404'
    }
}

export const getAgenda = async () => {
    try {
        const response = await axios.get(`${URL_SERV}/api/agenda`);
        return response.data;
    } catch (error) {
        return '404'
    }
}