import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJogos, getFisio, getEscaloes } from '../../store/actions';
import { Row, Col, Form, FloatingLabel, Card, Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";



const FormFisioJogos = ({ register, errors, jogos }) => {

    const [{ escalao_selected, campos_selected, jogo_selected }, setData] = useState({
        escalao_selected: 'none',
        campos_selected: 'none',
        jogo_selected: 'none'
    });

    const [escaloes, setEscaloes] = useState(null);
    const [campos, setCampos] = useState(null);
    const [jogos_select, setJogosSelect] = useState(null);

    const calculaEscaloes = (jogos) => {
        //get only distinct values from jogos.escaloes_id
        const result = [];
        const uniqueEscalaoSet = new Set();

        for (const jogo of jogos) {
            const { escaloes_id, escalao } = jogo;
            if (!uniqueEscalaoSet.has(escalao)) {
                result.push({ escaloes_id, escalao });
                uniqueEscalaoSet.add(escalao);
            }
        }

        return result;
    }

    const calculaCampos = (jogos, escalao_id) => {
        const result = [];
        const uniqueCamposSet = new Set();

        for (const jogo of jogos) {
            const { escaloes_id, campo, campos_id } = jogo;
            if (!uniqueCamposSet.has(campos_id) && escalao_id == escaloes_id) {
                result.push({ campos_id, campo });
                uniqueCamposSet.add(campos_id);
            }
        }

        return result;
    }

    const calculaJogos = (jogos, escalao_id, campo_id) => {
        const result = [];
        const uniqueJogosSet = new Set();

        for (const jogo of jogos) {
            const { escaloes_id, campos_id, id, equipacasa, equipafora } = jogo;
            if (!uniqueJogosSet.has(id) && escalao_id == escaloes_id && campo_id == campos_id) {
                result.push({ id, equipacasa, equipafora });
                uniqueJogosSet.add(jogo);
            }
        }
        return result;
    }

    const escaloes_data = [<option key="escaloes-0" value="none" disabled hidden> </option>,escaloes?.map((item) => (
            <option key={`escaloes-${item.escaloes_id}`} value={item.escaloes_id}>
                {item.escalao}
            </option>
    ))];

    const campos_data = [<option key="campos-0" value="none" disabled hidden> </option>,campos?.map((item) => (
            <option key={`campos-${item.campos_id}`} value={item.campos_id}>
                {item.campo}
            </option>
    ))];

    const jogos_data = [<option key="jogos-0" value="none" disabled hidden> </option>,jogos_select?.map((item) => (
            <option key={`jogos-${item.id}`} value={item.id}>
                {`${item.equipacasa} VS ${item.equipafora}`}
            </option>
    ))];

    useEffect(() => {
        if (jogos && jogos.length > 0) {
            const escaloesResult = calculaEscaloes(jogos);
            setEscaloes(escaloesResult);
        }
    }, [jogos]);

    function handleEscalaoChange(event) {
        setData(data => ({ campos_selected: 'none', jogo_selected: 'none', escalao_selected: event.target.value }));
        setCampos(calculaCampos(jogos, event.target.value));
    }

    function handleCamposChange(event) {
        setData(data => ({ ...data, campos_selected: event.target.value, jogo_selected: 'none' }));
        setJogosSelect(calculaJogos(jogos, escalao_selected, event.target.value));
    }
    function handleJogoChange(event) {
        setData(data => ({ ...data, jogo_selected: event.target.value }));
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>Selecionar jogo</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row className='mb-3'>
                        <Col>
                            <FloatingLabel controlId="floatingSelect" label="Escalão">
                                <Form.Select isInvalid={errors?.escalao} {...register('escalao', { onChange: (e) => { handleEscalaoChange(e) } })} aria-label="Escalão" value={escalao_selected}>
                                    {escaloes_data}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.escalao?.message}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            <FloatingLabel controlId="floatingSelect" label="Campos">

                                <Form.Select isInvalid={errors?.escalao} {...register('campo', { onChange: (e) => { handleCamposChange(e) } })} aria-label="Campo" value={campos_selected}>
                                    {campos_data}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.campo?.message}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            <FloatingLabel controlId="floatingSelect" label="Jogos">
                                <Form.Select isInvalid={errors?.jogo} {...register('jogo', { onChange: (e) => { handleJogoChange(e) } })} aria-label="Jogo" value={jogo_selected}>
                                    {jogos_data}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.jogo?.message}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );

}

export default FormFisioJogos;